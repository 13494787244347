<template>
    <div class="live-judge full-height full-width overflow-hidden d-flex flex-column">
        <template v-if="loading">
            <div class="full-height full-width d-flex align-center justify-center">
                <v-progress-circular :width="3" color="primary" indeterminate></v-progress-circular>
            </div>
        </template>

        <template v-else>
            <v-card
                class="elevation-0 d-flex align-center justify-space-between px-6 flex-shrink-0 full-width"
                height="60"
            >
                <div class="d-flex align-center justify-space-between flex-shrink-1 overflow-hidden">
                    <v-btn
                        class="mr-3"
                        icon
                        small
                        @click="
                            $router.push({
                                name: 'live-competitions-id',
                                params: { competition: $route.params.competition, room: $route.params.room },
                            })
                        "
                    >
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>

                    <div class="text-overflow">
                        <b>
                            {{ competition.title }} //
                            <span class="primary--text">{{ room.title }}</span>
                        </b>
                    </div>
                </div>

                <div class="actions d-flex align-center">
                    <v-btn class="ml-3" icon small @click="switchTheme()">
                        <v-icon v-html="$vuetify.theme.dark ? 'mdi-weather-sunny' : 'mdi-weather-night'"></v-icon>
                    </v-btn>

                    <v-btn class="ml-3" icon small @click="init()">
                        <v-icon>mdi-refresh</v-icon>
                    </v-btn>

                    <v-btn class="ml-3" icon small @click="$router.push({ name: 'live-competitions' })">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </div>
            </v-card>

            <template v-if="submitted">
                <v-container class="full-height full-width d-flex align-center flex-column justify-center">
                    <v-icon color="success" size="80" class="mb-6">mdi-check-circle</v-icon>

                    <h2 class="text-center mb-6">Your score has been successfully submitted!</h2>

                    <p class="text-center mb-0">Sending you back to the waiting room...</p>
                </v-container>
            </template>

            <template v-else>
                <div class="overflow-hidden full-height full-width flex-shrink-1">
                    <v-container class="full-width full-height d-flex flex-column align-center overflow-hidden pa-6">
                        <div class="full-width mb-6 d-flex align-center justify-space-between">
                            <b>Round {{ round }}</b>
                        </div>

                        <v-img
                            v-if="!room?.data?.active?.data?.videoSourceFull"
                            class="light rounded-lg full-width full-height flex-shrink-1"
                            :src="$fluro.asset.imageUrl(image)"
                            alt="avatar"
                            contain
                        >
                            <template v-slot:placeholder>
                                <v-row class="fill-height light ma-0" align="center" justify="center">
                                    <v-progress-circular
                                        :width="2"
                                        :size="20"
                                        color="dark"
                                        indeterminate
                                    ></v-progress-circular>
                                </v-row>
                            </template>
                        </v-img>
                        <template v-else-if="room?.data?.active?.data?.videoSourceFull">
                            <div class="select-container">
                                <v-select
                                    v-model="selectedVideo"
                                    :items="videos"
                                    align-center
                                    justify-center
                                    class="custom-select"
                                    item-text="label"
                                ></v-select>
                            </div>

                            <iframe
                                class="light full-width full-height cursor-pointer"
                                v-if="videoSource"
                                width="100%"
                                height="315"
                                :src="videoSource"
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                            ></iframe>
                        </template>
                        <div class="d-flex align-center">
                            <v-text-field
                                type="number"
                                pattern="\d*"
                                inputmode="decimal"
                                autofocus
                                ref="scoreField"
                                class="score-field"
                                v-model="score"
                                :rules="[rules.max]"
                                solo
                                flat
                                hide-details
                                reverse
                                placeholder="0"
                            ></v-text-field>

                            <div class="out-of">
                                <b class="primary--text">/ 100</b>
                            </div>
                        </div>

                        <div class="d-flex align-center">
                            <v-btn
                                class="mx-auto"
                                large
                                color="primary"
                                type="submit"
                                :disabled="!valid"
                                @click="
                                    $refs.alertDialog.open(
                                        active,
                                        `Are you sure you want to submit your score of <b>${score}</b> for this entry?`
                                    )
                                "
                                >Submit Score</v-btn
                            >
                        </div>
                    </v-container>
                </div>
            </template>
        </template>

        <v-snackbar v-model="snackbar" timeout="6000" :color="snackbarColor" top>
            <span class="full-width text-center d-block">{{ snackbarText }}</span>
        </v-snackbar>

        <alert-dialog ref="alertDialog" @confirm="submit" title="Submit Score"></alert-dialog>
    </div>
</template>

<script>
import Room from "@/modules/app-module/room";
import Score from "@/modules/app-module/score";
import Entry from "@/modules/app-module/entry";
import ScoreCard from "@/modules/app-module/score-card";

import AlertDialog from "@/components/dialogs/alert-dialog.vue";

export default {
    name: "live-judge",

    meta() {
        return {
            title: this.room?.title || "Room",
        };
    },

    components: {
        AlertDialog,
    },

    data() {
        return {
            videos: [{ label: "Short Video" }, { label: "Full Video" }],
            selectedVideo: "Short Video", // default selection
            loading: false,
            room: {},
            score: null,
            submitted: null,
            active: null,
            snackbar: false,
            snackbarText: "",
            snackbarColor: "",
            scorecards: [],
            rules: {
                max: (v) => {
                    if (!isNaN(parseFloat(v)) && v >= 0 && v <= 100) return true;
                    return "Number has to be between 0 and 100";
                },
            },
        };
    },

    computed: {
        videoSource() {
            return this.selectedVideo == "Short Video"
                ? this.room.data.active.data.videoSourceShort
                : this.room.data.active.data.videoSourceFull;
        },
        competition() {
            return this.room?.data?.competition || {};
        },
        category() {
            return this.room?.data?.category || {};
        },
        image() {
            return this?.room?.data?.active?.data?.image || null;
        },
        judges() {
            return this.room?.data?.activeJudges || [];
        },
        round() {
            let scorecards = this.scorecards;

            scorecards = scorecards.filter((card) => card?.data?.contested || card?.data?.restarted);

            return scorecards.length + 1;
        },
        isJudge() {
            let user = this.$app.user;

            return this.judges.find((judge) => judge.judge._id == user.persona);
        },
        valid() {
            let score = this.score;

            if (!score) return false;

            if (score > 100 || score < 1) return false;

            if (isNaN(score)) return false;

            return true;
        },
        hasSubmitted() {
            let user = this.$app.user;
            let scores = this.active?.posts?.score?.posts || [];

            let hasSubmitted = scores.some(
                (score) => score?.managedAuthor == user.persona && score.data.round == this.round
            );

            return hasSubmitted;
        },
    },

    methods: {
        async init() {
            this.score = null;
            this.loading = true;

            try {
                await this.socketSetup();
                await this.$fluro.resetCache();

                this.room = await Room.get(this.$route.params.room).then(({ data }) => data);
                console.log("ROOM", this.room);

                let active = this.room?.data?.active || {};

                // The user is still an active judge
                if (!this.isJudge) {
                    console.log("not judge");
                    this.goBack({ removed: true });
                }
                // Entry is no longer active
                else if (!active?._id) {
                    console.log("not active");
                    this.goBack();
                }
                // Entry is still active, get
                else {
                    let user = this.$app.user;
                    let judge = this.room?.data?.activeJudges.find((judge) => judge?.judge?._id == user.persona);
                    let judgeId = this.$route.params.judge;

                    // User is an active judge, populate the entry and get posts
                    if (judge?.judge?._id == judgeId) {
                        this.active = await Entry.get(active._id, { params: { appendPosts: "all" } }).then(
                            ({ data }) => data
                        );
                        console.log("ACTIVE", this.active);

                        let query = {
                            _type: "article",
                            definition: "scoreCard",
                            "data.entry": `ObjectId(${this.active._id})`,
                        };

                        this.scorecards = await ScoreCard.query(query).then(({ data }) => data);
                        console.log("SCORE CARDS", this.scorecards);

                        if (this.hasSubmitted) {
                            this.goBack({ submitted: true });
                        }
                    }
                    // User is not an active judge
                    else {
                        this.goBack({ removed: true });
                    }
                }
            } catch (err) {
                console.log("ERROR", err);
            }

            this.loading = false;
        },
        /////////////////////////START SOCKET HANDELING////////////////////////////
        async socketSetup() {
            console.log("Starting Socket Test");
            this.socket = new WebSocket(
                `wss://smhb2elzv9.execute-api.ap-southeast-2.amazonaws.com/production?persona=${this.$app.user.persona}&room=${this.$route.params.room}`
            );

            console.log("SOCKET", this.socket);
            this.socket.onopen = () => {
                console.log("Connected to WebSocket.");

                // Send a join command/message to the server
                const joinMessage = {
                    command: "join",
                    room: this.$route.params.room,
                    persona: this.$app.user.persona,
                    name: this.$app.user.name,
                };
                this.socket.send(JSON.stringify(joinMessage));
                this.startPing();
            };

            this.socket.onmessage = async (event) => {
                console.log("Received data from WebSocket: ", event.data);

                const data = JSON.parse(event.data);

                if (data.action == "refresh") {
                    this.$router.push({
                        name: "live-competitions-room",
                        params: {
                            competition: this.$route.params.competition,
                            // category: this.$route.params.category,
                            room: this.room._id,
                        },
                    });
                } else if (data.action == "create") {
                    await this.getRoomScribe();
                }
            };

            this.socket.onclose = () => {
                console.log("Disconnected from WebSocket.");
                this.stopPing();
            };

            this.socket.onerror = (error) => {
                console.error("WebSocket error: ", error);
                this.stopPing();
            };
        },
        closeWebSocket() {
            if (this.socket) {
                this.socket.close();
                this.socket = null;
            }
        },
        startPing() {
            this.pingInterval = setInterval(() => {
                if (this.socket && this.socket.readyState === WebSocket.OPEN) {
                    const createMessage = {
                        command: "pingScribe",
                        name: this.$app.user.name,
                        persona: this.$app.user.persona,
                        scribeConnection: this.scribeConnection,
                    };
                    this.socket.send(JSON.stringify(createMessage));
                }
            }, 1500); // Ping interval in milliseconds
        },
        stopPing() {
            clearInterval(this.pingInterval);
            this.pingInterval = null;
        },
        socketSubmit() {
            const message = {
                command: "submit",
                room: this.$route.params.room,
                persona: this.$app.user.persona,
                name: this.$app.user.name,
            };
            this.socket.send(JSON.stringify(message));
        },
        async getRoomScribe() {
            //Get All detail Sheets
            const roomQuery = {
                definition: "socket",
                status: "active",
                "data.room": this.$route.params.room,
                // "data.activeOrgs": { $in: [currentOrg] }, //TO DO, make this work to increase performance
            };
            console.log(this.$route.params.room);
            const response = await this.$fluro.api.post("/content/_query", roomQuery).then(({ data }) => data);
            console.log("GOT SCRIBE", response);
            this.scribeConnection = response[0].data.scribe;
        },
        /////////////////////////END SOCKET HANDELING////////////////////////////
        async submit(item) {
            try {
                await this.$fluro.resetCache();

                this.room = await Room.get(this.$route.params.room).then(({ data }) => data);
                console.log("ROOM", this.room);

                let active = this.room?.data?.active || {};

                // The user is still an active judge
                if (!this.isJudge) {
                    this.goBack({ removed: true });
                }
                // Entry is no longer active
                else if (active?._id != item._id) {
                    this.$refs.alertDialog.close();
                    await this.init();

                    this.snackbar = true;
                    this.snackbarText = "The scribe has changed the active artwork, your score was not submitted";
                    this.snackbarColor = "warning";
                }
                // User has already submitted their score - this step will probably never happen
                else if (this.hasSubmitted) {
                    this.goBack({ submitted: true });
                }
                // Entry is still active, submit
                else {
                    let user = this.$app.user;
                    let competition = this.competition;

                    let sandbox = competition.realms.some(
                        (realm) => realm?._id == this.$app.sandboxRealm || realm == this.$app.sandboxRealm
                    );

                    let payload = {
                        title: `Score for ${item.title}`,
                        realms: [sandbox ? this.$app.sandboxRealm : this.$app.liveRealm],
                        data: {
                            score: this.score,
                            round: this.round,
                            submitted: true,
                            live: true,
                        },
                        parent: item,
                        managedOwners: [user.persona],
                    };

                    this.submitted = await Score.create(payload).then(({ data }) => data);
                    console.log("SCORE", this.submitted);
                    this.socketSubmit();

                    this.$refs.alertDialog.close();

                    setTimeout(() => {
                        this.$router.push({
                            name: "live-competitions-room",
                            params: {
                                competition: this.$route.params.competition,
                                // category: this.$route.params.category,
                                room: this.room._id,
                            },
                        });
                    }, 1000);
                }
            } catch (err) {
                console.log("ERROR", err);
                this.$refs.alertDialog.error(err);
            }
        },
        switchTheme() {
            this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
            window.localStorage["dark"] = this.$vuetify.theme.dark;
        },
        goBack(query) {
            query = query || {};

            this.$router
                .push({
                    name: "live-competitions-room",
                    params: {
                        competition: this.$route.params.competition,
                        // category: this.$route.params.category,
                        room: this.$route.params.room,
                    },
                    query,
                })
                .catch(() => {});
        },
    },

    async mounted() {
        await this.init();
        await this.getRoomScribe();
        // this.$nextTick(() => {
        //     this.$refs.scoreField.focus();
        // });
    },
    destroyed() {
        this.closeWebSocket();
    },
};
</script>

<style lang="scss">
.live-judge {
    .score-field {
        width: 100%;

        .v-input__control {
            min-height: initial;
            height: auto !important;

            .v-input__slot {
                padding: 12px !important;
                background: transparent !important;

                input {
                    font-size: 50px;
                    max-height: initial;
                    font-weight: 700;
                }
            }
        }

        &.error--text {
            input {
                color: var(--v-error-base) !important;
            }
        }
    }

    .out-of {
        width: 100%;
        font-size: 50px;

        b {
            font-weight: 700 !important;
        }
    }
}
</style>
